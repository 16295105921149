import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'feed-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input() config: any;
  @Input() name: string;
  @Input() deleteName: any;
  translatedName: any;
  translatedReport: any;
  deleteTrName: any;
  isMobile: boolean = false;
  userInfo: any[] = [];

  @Output() emitEditClick = new EventEmitter();
  @Output() emitDeleteClick = new EventEmitter();
  @Output() emitInappropriateClick = new EventEmitter();
  isDropdown: boolean = false;
  constructor(
    private coreService: CoreService,
    private screenSizeService: ScreenSizeService
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 800;
    });
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit(): void {
    if (this.deleteName === 'RePat') {
      this.deleteTrName = this.coreService.getTranslation('DELETE_REPAT');
    }
    if (this.name === 'pat') {
      this.translatedName = this.coreService.getTranslation('DELETE_PAT');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_PAT_INAPPROPRIATE'
      );
    } else if (this.name === 'Token') {
      this.translatedName = this.coreService.getTranslation('DELETE_TOKEN');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_TOKEN_INAPPROPRIATE'
      );
    } else if (this.name === 'Feedback') {
      this.translatedName = this.coreService.getTranslation('DELETE_FEEDBACK');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_FEEDBACK_INAPPROPRIATE'
      );
    } else if (this.name === 'RePat') {
      this.translatedName = this.coreService.getTranslation('DELETE_REPAT');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_REPAT_INAPPROPRIATE'
      );
    } else if (this.name === 'info') {
      this.translatedName = this.coreService.getTranslation('DELETE_INFO');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_INFO_INAPPROPRIATE'
      );
    } else if (this.name === 'Event') {
      this.translatedName = this.coreService.getTranslation('DELETE_EVENT');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_EVENT_INAPPROPRIATE'
      );
    } else if (this.name === 'success') {
      this.translatedName = this.coreService.getTranslation('DELETE_SUCCESS');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_SUCCESS_INAPPROPRIATE'
      );
    } else if (this.name === 'comment') {
      this.translatedName = this.coreService.getTranslation('DELETE_COMMENT');
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_COMMENT_INAPPROPRIATE'
      );
    } else if (this.name === 'KeyResult') {
      this.translatedName =
        this.coreService.getTranslation('DELETE_KEY_RESULT');
    } else {
      this.translatedName = this.coreService.getTranslation(
        'DELETE_FEEDBACK_REQUEST'
      );
      this.translatedReport = this.coreService.getTranslation(
        'REPORTS_FEEDBACK_REQUEST'
      );
    }
  }

  isOpenChange() {}
  onHidden() {}
  onShown() {
    if (this.isMobile) {
      $('body').removeClass('mini-navbar');
    }
  }
}
