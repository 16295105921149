import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  replaceHeader$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  getConfig$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  filterWall$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  refreshWall$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  _appConfig: any = {
    availableTokens: 0,
    earnedTokens: 0,
    showIcons: true,
    showTitle: false,
    pageTitle: '',
    pageIcon: '',
    showFilter: false,
  };

  constructor() {
    this.replaceHeader$.subscribe((newHeader: any) => {
      /*
       COMMENTATO NON MODIFICARE HEADER
        console.log('replaceHeader', newHeader); 
        if (newHeader && newHeader.showIcons) this._appConfig.showIcons = newHeader.showIcons;
        if (newHeader && newHeader.showTitle) this._appConfig.showTitle = newHeader.showTitle;
        if (newHeader && newHeader.pageTitle) this._appConfig.pageTitle = newHeader.pageTitle;
        if (newHeader && newHeader.pageIcon) this._appConfig.pageIcon = newHeader.pageIcon;
        this.getConfig$.next(this._appConfig);
*/
    });
    //this.getConfig$.next(this._appConfig);
  }

  setAvailableTokens(availableTokens: number) {
    this._appConfig.availableTokens = availableTokens;
  }

  setEarnedTokens(earnedTokens: number) {
    this._appConfig.earnedTokens = earnedTokens;
  }

  isMacChrome(): boolean {
    const userAgent = navigator.userAgent;
    return (
      /Macintosh/.test(userAgent) &&
      /Chrome/.test(userAgent) &&
      !/CriOS/.test(userAgent)
    );
  }
}
